import { Amplify, API } from 'aws-amplify';
import mitt from 'mitt';
import sha256 from 'crypto-js/sha256';

Amplify.configure({
    // Auth: {
    //     // REQUIRED - Amazon Cognito Identity Pool ID
    //     identityPoolId: 'ap-southeast-1:9a3d2af5-a325-438a-9966-1a5cc97b8b6c',
    //     // REQUIRED - Amazon Cognito Region
    //     region: 'ap-southeast-1', 
    //     // OPTIONAL - Amazon Cognito User Pool ID
    //     userPoolId: 'ap-southeast-1_d5RTKSqoe', 
    //     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    //     userPoolWebClientId: '6m2jli69m7p6n3va8dcredri64',
    // },
    API: {
        endpoints: [
            {
                name: "query",
                endpoint: "https://y1sfzb6xzg.execute-api.ap-east-1.amazonaws.com/production",
                // endpoint: "https://uhxc9591ej.execute-api.ap-east-1.amazonaws.com/development",
                service: 'execute-api',
                region: 'ap-southeast-1',
            },
        ]
    },
});



const emitter = mitt();

const gql = (strings, ...vars) => {
    let result = '';
    strings.forEach((str, i) => {
        result += `${str}${i === strings.length - 1 ? '' : vars[i]}`;
    });
    return result;
};

const post = async (apiName, path, init) => {
    const geolocation = await getGeolocation();
    // console.log(geolocation)
    if (geolocation == null) {
        return;
    }
    const { timestamp, coords: { latitude, longitude } } = geolocation;
    const hash = sha256(nonce + [timestamp, latitude, longitude].join(',')).toString();

    init.headers = Object.assign(
        init.headers || {},
        {
            timestamp,
            latitude,
            longitude,
            hash
        }
    );

    const t = Date.now().toString(36);
    emitter.emit('server:loading', { t });
    return API.post(apiName, path, init)
        .catch(error => {
            console.log(error);
            throw error;
        })
        .then(r => {
            emitter.emit('server:loaded', { t });
            return r;
        });
}
const nonce = 'ceddltv';
const addWork = ({ model }) => {
    const query = gql`mutation addWork($work:WorkInput){
        addWork(work:$work) {
          id
          drawingPath
          signedUrl
        }
      }`;

    return post('query', '/graphql', {
        response: true,
        body: {
            query,
            variables: { work: { id: ' ', model, drawingPath: ' ' } }
        }
    })
        .catch(error => {
            console.log(error);
            throw error;
        })
        .then(r => {
            return r.data.data.addWork
        });
}

const setWorkReady = ({ id, model }) => {
    const query = gql`mutation setWorkReady($work: WorkInput) {
        setWorkReady(work: $work) {
          id
          drawingPath
          signedUrl
        }
      }`;

    return post('query', '/graphql', {
        response: true,
        body: {
            query,
            variables: { work: { id, model, drawingPath: '' } }
            // variables: { work: { id: hash, drawingPath: [timestamp, latitude, longitude].join(',') } }
        }
    })
        .catch(error => {
            console.log(error);
            throw error;
        })
        .then(r => {
            return r.data.data.addWork
        });
}

const callAction = (action) => {
    const query = gql`mutation Mutation($action: ActionInput!) {
        callAction(action: $action) {
          id
        }
      }`;

    return post('query', '/graphql', {
        response: true,
        body: {
            query,
            variables: {
                "action": action
            }
        }
    })
        .catch(error => {
            console.log(error)
        })
        .then(r => {
            return r.data.data.addWork
        });
}

let geolocation = {
    coords: {
        latitude: 0,
        longitude: 0
    },
    timestamp: 0
};

const getGeolocation = () => {
    if (geolocation) {
        return Promise.resolve(geolocation);
    }
    geolocation = {
        coords: {
            latitude: 0,
            longitude: 0,
        },
        timestamp: Date.now()
    }
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {
                console.log(pos)
                geolocation = pos;
                resolve(pos);
            }, err => {
                resolve(geolocation);
                // reject(err);
            });
        } else {
            resolve(geolocation);
            // reject();
        }
    })
}

const Repository = {
    emitter,
    addWork,
    callAction,
    getGeolocation,
    setWorkReady,
}

export { Repository as default };