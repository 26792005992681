import m from 'mithril';
import * as PIXI from 'pixi.js'
// import { Extract } from '@pixi/extract';
import { Icon } from './icons';
// PIXI.Renderer.registerPlugin('extract', Extract);

const createDrawingPad = async (emitter, viewer, guidePath, drawingsvg) => {
    const video = viewer.video;
    const app = new PIXI.Application({ width: 1024, height: 1024, backgroundColor: 0xFFFFFF });
    const container = new PIXI.Container();
    container.x = 512;
    container.y = 512;
    app.stage.addChild(container);
    // const texture = PIXI.Texture.from(video);
    const img = document.createElement('img');
    img.src = await viewer.captureVideoTexture();
    const texture = PIXI.Texture.from(img);
    const photo = new PIXI.Sprite(texture);
    const scale = 1;// video.scaleX * 1024 / video.videoWidth;

    app.toImageDateURL = async () => {
        const texture = app.renderer.generateTexture(container, { region: new PIXI.Rectangle(0, 0, 1024, 1024) });
        const sprite = new PIXI.Sprite(texture);
        const img = await app.renderer.extract.image(sprite, 'image/png');
        return img.src;
    }

    photo.scale.set(scale);
    photo.anchor.set(0.5, 0.5);
    photo.position.set(0, 0)

    container.addChild(photo);
    video.pause();

    const originalTexture = PIXI.Texture.from(drawingsvg);
    const original = new PIXI.Sprite(originalTexture);
    original.anchor.set(0.5, 0.5);
    original.visible = false;
    container.addChild(original);

    const drawing = new PIXI.Container();
    container.addChild(drawing);

    const graphics = new PIXI.Graphics();
    graphics.x = -512;
    graphics.y = -512;
    container.addChild(graphics);
    container.interactive = true;

    const mask = PIXI.Sprite.from(guidePath);
    mask.blendMode = PIXI.BLEND_MODES.DST_IN;
    mask.anchor.set(0.5, 0.5);
    container.mask = mask;
    container.addChild(mask);

    const strokeWith = 50;
    const lineStyle = {
        width: strokeWith,
        color: 0xEB1B23,
        // alignment: 0.5,
        alpha: 1,
        join: 'round',
        cap: 'round',
    }

    emitter.on('color', c => lineStyle.color = Number(c.replace(/^#/, '0x')));
    emitter.on('showOriginal', () => {
        drawing.removeChildren();
        graphics.clear();
        original.visible = true;
        photo.visible = false;
    });
    emitter.on('showPhoto', () => {
        drawing.removeChildren();
        graphics.clear();
        original.visible = false;
        photo.visible = true;
    });

    let points = [];
    container.on('pointerdown', ({ data: { global: { x, y } } }) => {
        graphics.lineStyle(lineStyle);
        points.push({ x: Math.round(x), y: Math.round(y) });
    });

    container.on('pointermove', ({ data: { global: { x, y } } }) => {
        const point = points[points.length - 1];
        if (!point) return;
        const nx = Math.round(x);
        const ny = Math.round(y);
        if (point.x != nx || point.y != ny) {
            points.push({ x: nx, y: ny });
            const line = new PIXI.Polygon(points);
            line.closeStroke = false;
            graphics.drawShape(line);
        }
    });

    container.on('pointerup', (e) => {
        const { x, y } = graphics.getBounds();
        const texture = app.renderer.generateTexture(graphics);
        const sprite = new PIXI.Sprite(texture);
        sprite.x = x - 512;
        sprite.y = y - 512;
        drawing.addChild(sprite);
        points = [];
        graphics.clear();
    });

    return app;
}

const drawingPage = {
    oninit: vnode => {
        const colorActions = [];
        vnode.state.setColorRegions = regions => {
            for (let i = 0; i < regions.length; i++) {
                const region = regions[i];
                region.style.opacity = 0;
                region.orignalColor = region.style.fill;
                // region.addEventListener('click', e => {
                //     colorActions.push({ target: e.target, color: e.target.style.fill, opacity: e.target.style.opacity });
                //     e.target.style.fill = vnode.state.color;
                //     e.target.style.opacity = 1;
                // })
            }
        }
        vnode.state.colorRegions = color => {
            const regions = vnode.state.drawingSvg.children;
            for (let i = 0; i < regions.length; i++) {
                const region = regions[i];
                // region.style.opacity = 0;
                region.style.fill = color;
                region.style.opacity = 1;
            }
        }
        vnode.state.undoColor = () => {
            if (colorActions.length) {
                const { target, color, opacity } = colorActions.pop();
                target.style.fill = color;
                target.style.opacity = opacity;
            }
        }
        vnode.state.orignalColor = () => {
            const regions = vnode.state.drawingSvg.children;
            for (let i = 0; i < regions.length; i++) {
                const region = regions[i];
                // region.style.opacity = 0;
                region.style.fill = region.orignalColor;
                region.style.opacity = 1;
            }
        }
        vnode.state.clearColor = () => {
            const regions = vnode.state.drawingSvg.children;
            for (let i = 0; i < regions.length; i++) {
                const region = regions[i];
                // region.style.opacity = 0;
                region.style.opacity = 0;
            }
        }
        vnode.state.finishDrawing = async () => {
            const { emitter } = vnode.attrs;
            emitter.emit('textureDataUrl', await vnode.state.drawingPad.toImageDateURL());
            emitter.emit('mode', 'showModel');
        }
        vnode.state.curColor = '#EB1B23';
        vnode.attrs.emitter.on('color', c => vnode.state.curColor = c);
        vnode.state.drawingsvg = vnode.attrs.drawingPath;

    },
    onremove: (vnode) => {

    },
    view: vnode => {
        const { emitter, viewer, guidePath, drawingPath, titlePath } = vnode.attrs;
        const { finishDrawing, curColor } = vnode.state;
        // if (!drawingsvg) return null;
        return m(
            '.grid-y.grid-frame',
            m('.cell.small-1', m('.title', m('img', { src: titlePath }))),
            m(
                '.cell.small-6',
                m(
                    '.canvas.drawing',
                    {
                        oncreate: async vnode2 => {
                            const app = await createDrawingPad(emitter, viewer, guidePath, drawingPath);
                            vnode.state.drawingPad = app;
                            vnode2.dom.appendChild(app.view);
                        }
                    }
                ),
            ),
            m(
                '.cell.small-3',
                m(
                    '.text-center',
                    m(Icon, { id: '0brushBtn', attrs: 'class="brush-icon"' })
                ),
                m(
                    '.grid-x.color-picker',
                    m('.cell.small-1'),
                    [
                        // { color: '#ed1c24', icon: '2_color_1' },
                        { color: '#EB1B23', icon: '2red' },
                        { color: '#F6911D', icon: '1orange' },
                        { color: '#FBED20', icon: '3yellow' },
                        { color: '#009245', icon: '4green' },
                        { color: '#0270BA', icon: '5blue' },
                    ]
                        .map(({ color, icon }) => m(
                            '.cell.small-2',
                            m(
                                'a.color-button',
                                {
                                    className: color == curColor ? 'active' : '',
                                    // style: `background-color:${color}`,
                                    // onclick: e => colorRegions(color),
                                    onclick: e => emitter.emit('color', color),
                                },
                                m('.icon-button', m(Icon, { id: icon }))
                            )
                        )),
                ),
                m(Icon, { id: '6line', attrs: 'class="line"' }),
                m(
                    '.grid-x',
                    m(
                        '.cell.small-2.small-offset-4',
                        m('a.icon-button', { onclick: e => emitter.emit('showOriginal') }, m(Icon, { id: '7color' })),
                    ),
                    (
                        '.cell.small-2',
                        m('a.icon-button', { onclick: e => emitter.emit('showPhoto') }, m(Icon, { id: '8un' }))
                    )
                ),
            ),
            m(
                '.cell.small-1',
                m('a.large-button', {
                    onclick: finishDrawing
                }, m(Icon, { id: '2_life_btn', attrs: 'viewBox="0 0 940 231"' }))
            )
        );
    }
}

export { drawingPage as default }