import m from 'mithril';
import { Icon } from './icons';
import gsap from 'gsap';

const controlPad = {
    oninit: vnode => {
        vnode.state.controllerPos = { x: 0, y: 0, dotX: 0, dotY: 0 };
    },
    view: vnode => {
        const { emitter, screenWidth, screenHeight } = vnode.attrs;
        const { x, y, dotX, dotY, pressed } = vnode.state.controllerPos;
        return m(
            '.control-pad',
            {
                oncreate: vnode2 => {
                    vnode.state.offsetTop = vnode2.dom.offsetTop;
                    vnode.state.controllerPos.x = vnode2.dom.offsetWidth/2;
                    vnode.state.controllerPos.y = vnode2.dom.offsetHeight/2;
                    vnode.state.controllerPos.pressed = true;
                    m.redraw();
                },
                ontouchstart: e => {
                    console.log(e);
                    let { clientX: x, clientY: y } = e.touches[0];
                    y -= vnode.state.offsetTop;
                    vnode.state.controllerPos.x = x;
                    vnode.state.controllerPos.y = y;
                    vnode.state.controllerPos.dotX = 0;
                    vnode.state.controllerPos.dotY = 0;
                    vnode.state.controllerPos.pressed = true;
                    vnode.state.lastMoveSentTime = performance.now();
                    vnode.state.lastDx = 0;
                    vnode.state.lastDy = 0;
                    gsap.to(vnode.state.dot, { duration: 0.1, x: 0, y: 0 });
                },
                ontouchmove: e => {
                    e.redraw = false;
                    let { clientX: x1, clientY: y1 } = e.touches[0];
                    y1 -= vnode.state.offsetTop;
                    vnode.state.controllerPos.x = x;
                    vnode.state.controllerPos.y = y;
                    let dx = x1 - x;
                    let dy = y1 - y;
                    let r = Math.min(100 / Math.sqrt(dx * dx + dy * dy), 1);
                    dx = vnode.state.controllerPos.dotX = dx * r;
                    dy = vnode.state.controllerPos.dotY = dy * r;
                    // vnode.state.dot.style.transform = `translate(${dx}px,${dy}px)`;
                    gsap.to(vnode.state.dot, { duration: 0.1, x: dx, y: dy });
                    const curTime = performance.now();
                    if ((curTime - vnode.state.lastMoveSentTime) > 200) {
                        vnode.state.lastMoveSentTime = curTime;
                        if (Math.abs(vnode.state.lastDx - dx) > 2 || Math.abs(vnode.state.lastDx - dx) > 2) {
                            vnode.state.lastDx = dx;
                            vnode.state.lastDy = dy;
                            const data = { op: 'move', data: [Math.round(dx * 100) / 10000, Math.round(dy * 100) / 10000] };
                            const sendData = () => {
                                clearTimeout(vnode.state.scheduledSend);
                                if (vnode.state.controllerPos.pressed) {
                                    emitter.emit('action', data);
                                    vnode.state.scheduledSend = setTimeout(sendData, 2000);
                                }
                            };
                            sendData();
                        }
                    }
                },
                ontouchend: e => {
                    vnode.state.controllerPos.pressed = false;
                    clearTimeout(vnode.state.scheduledSend);
                    emitter.emit('action', { op: 'move', data: [0, 0] });
                    gsap.to(vnode.state.dot, { duration: 0.1, x: 0, y: 0 });
                }
            },
            m(
                '.controller',
                {
                    // className: pressed ? 'active' : '',
                    className: 'active',
                    style: `left:${screenWidth/2}px; top:${screenHeight*0.22}px`,
                },
                m('.controller-bg', m(Icon, { id: 'control' })),
                m(
                    '.controller-c',
                    {
                        oncreate: vnode2 => vnode.state.dot = vnode2.dom
                    },
                    m(Icon, { id: '3_2_control_btn' })
                ),
            ),
        );
    }
}

const showModelPage = {
    view: vnode => {
        const { emitter, isViewOnly, titlePath, screenWidth, screenHeight } = vnode.attrs;
        const wid = m.route.param('id');
        return m(
            '.grid-y.grid-frame.show-model-page',
            vnode.children,
            m('.cell.small-1', m('.title', m('img', { src: titlePath }))),
            m(
                '.cell.small-2',
                isViewOnly ? null :
                    m(
                        '.grid-x.upper-bar',
                        m('.cell.small-4', m('a.icon-button', { onclick: e => emitter.emit('action', { op: 'bigger' }) }, m(Icon, { id: '3_1_bigger_btn' }))),
                        m('.cell.small-4', m('a.icon-button', { onclick: e => emitter.emit('action', { op: 'jump' }) }, m(Icon, { id: '3_1_jump_btn' }))),
                        m('.cell.small-4', m('a.icon-button', { onclick: e => emitter.emit('action', { op: 'blink' }) }, m(Icon, { id: '3_1_blink_bt' }))),
                    )
            ),
            m(
                '.cell.small-5',
                vnode.children,
                isViewOnly ? null :
                    m(controlPad, { emitter, screenWidth, screenHeight }),
                // isViewOnly?null:
                // m('a.touch-button', { onclick: e => emitter.emit('mode', 'control') }, m(Icon, { id: 'touchMe' }))
            ),
            m(
                '.cell.small-2.ui',
                // isViewOnly ? null :
                m('a.large-button', {
                    onclick: () => emitter.emit('mode', 'showAR')
                }, m(Icon, { id: '3_3_ar_btn', attrs: 'viewBox="0 0 940 231"' })),
            ),
            m(
                '.cell.small-2',
                m(
                    '.grid-x.lower-bar',
                    wid
                        ?m('.cell.small-6', m('a.icon-button-2', { onclick: e => emitter.emit('share') }, m(Icon, { id: '3_4_save_btn', attrs: 'viewBox="0 0 245.9 212.22"' })))
                        :m('.cell.small-6', m('a.icon-button-2', { style:'opacity:0.5' }, m(Icon, { id: '3_4_save_btn', attrs: 'viewBox="0 0 245.9 212.22"' }))),
                    m('.cell.small-6', m('a.icon-button-2', { onclick: e => emitter.emit('mode', 'takePhoto') }, m(Icon, { id: '3_4_again_btn', attrs: 'viewBox="0 0 194.97 223.39"' }))),
                )
            ),
        )
    }
}

export { showModelPage as default }