import m from 'mithril';
import { Icon } from './icons';

const takePhotoPage = {
    view: vnode => {
        const { emitter } = vnode.attrs;
        return m(
            '.grid-y.grid-frame.taking-photo',
            vnode.children,
            m('.cell.small-1'),
            m(
                '.cell.small-8',
            ),
            m(
                '.cell.small-2',
                m(
                    'a.large-button',
                    {
                        onclick: () => emitter.emit('mode', 'showDrawing')
                    },
                    m(Icon, { id: '1_takePhoto_btn', attrs: 'viewBox="0 0 940 231"' })
                )
            ),
        );
    }
}

export { takePhotoPage as default }