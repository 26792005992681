import m from 'mithril';
import Repository from './repositories/repository';
import arViewer from './arViewer';
import drawingPage from './drawingPage';
import takePhotoPage from './takePhotoPage';
import showModelPage from './showModelPage';
import showARPage from './showARPage';;
import mitt from 'mitt';
import { Icon } from './icons';
import * as BABYLON from 'babylonjs';

const version = 'v1.1.0';
// const TEXTURE_PATH = 'https://ltv-development-static.s3.ap-east-1.amazonaws.com/upload/{id}/texture.png';
const TEXTURE_PATH = 'https://ltv-production-static.s3.ap-east-1.amazonaws.com/upload/{id}/texture.png';
const MODE_TAKE_PHOTO = 'takePhoto';
const MODE_SHOW_DRAWING = 'showDrawing';
const MODE_SHOW_MODEL = 'showModel';
const MODE_SHOW_AR = 'showAR';

const emitter = new mitt();

const models = require.context('../model', true, /\.(png|svg|glb)/).keys()
    .map(k => k.match(/\.\/(\d+)\/c\d+(?:(?:_(.*?)\..*)|.glb)/))
    .reduce((map, item) => {
        if (item) {
            const path = item[0];
            const id = item[1];
            const type = item[2] || 'model';
            map[id] = map[id] || {};
            map[id][type] = `./model/${path.substr(2)}`
        }
        return map;
    }, {})
    ;

const setupListeners = (vnode) => {
    emitter.on('action', action => {
        if (!vnode.state.work) {
            return;
        }
        const id = vnode.state.work.id;
        Repository.callAction({ id, ...action })
            .then(r2 => {
            })
        if (action.op == 'move') {
            vnode.state.viewer.modelRoot.rotation.y = action.data[0] * Math.PI / 2 * 0.3;
            vnode.state.viewer.modelRoot.rotation.x = action.data[1] * Math.PI / 2 * 0.3;
        }
    })

    emitter.on('mode', mode => {
        vnode.state.viewer.setRunning(mode != MODE_SHOW_DRAWING);
        vnode.state.viewer.showModel(mode == MODE_SHOW_MODEL || mode == MODE_SHOW_AR);
        vnode.state.viewer.setARMode(mode == MODE_SHOW_AR);
        vnode.state.viewer.showVideo(mode == MODE_TAKE_PHOTO || mode == MODE_SHOW_AR);

        if (mode == MODE_TAKE_PHOTO) {
            vnode.state.wid = null;
            m.route.set(`/${vnode.state.model}`);
        }
        vnode.state.mode = mode;
        m.redraw();
    });

    emitter.on('share', () => {
        if (navigator.share) {
            navigator.share({
                title: 'Lantau Tomorrow Vision',
                url: location.href,
            }).then(() => {
                console.log('Thanks for sharing!');
            })
                .catch(console.error);
        }
    })

    emitter.on('textureDataUrl', dataUrl => {
        const texture = new BABYLON.Texture(dataUrl, vnode.state.viewer.scene, false, false);
        vnode.state.viewer.material.albedoTexture = texture;
        if (/^data:image\/png;base64/.test(dataUrl)) {
            sendToServer(vnode, dataUrl);
        }
    })

    emitter.on('saveARImage', () => {
        vnode.state.viewer.captureScreen(imgDataUrl => {
            emitter.emit('popup', () => m('img.ar-photo', { src: imgDataUrl }));
        });
    })
}

const sendToServer = async (vnode, dataUrl) => {
    const { model } = vnode.state;
    emitter.emit('popup', null);
    m.redraw();
    function dataURItoBlob(dataURI) {
        var byteString = window.atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    }
    Repository.addWork({ model })
        .then(work => {
            vnode.state.work = work;
            // console.log(work);
            const { id, drawingPath, signedUrl } = work;
            const body = dataURItoBlob(dataUrl);
            m.request({
                url: signedUrl,
                method: "PUT",
                body: body,
                serialize: v => v,
            })
                .catch(err => {
                    console.log(err);
                    emitter.emit('popup', () => m(
                        '',
                        m('h3', 'Failed to upload image'),
                        m('p', JSON.stringify(err)),
                        m('a.button', { onclick: e => sendToServer(dataUrl) }, 'Try again'),
                        m('a.button', { onclick: e => { emitter.emit('popup', null); Icon.refresh(); } }, 'Cancel'),
                    ));
                    m.redraw();
                })
                .then(r => {
                    // console.log(r);
                    m.redraw();
                    Repository.setWorkReady({ id, model })
                        .then(r2 => {
                            // console.log(r2);
                            emitter.emit('popup', null);
                            m.route.set(`/${model}/${id}`);
                            m.redraw();
                            Icon.refresh();
                            setTimeout(() => m.redraw(), 100);
                        })
                        .catch(err => {
                            console.log(err);
                            emitter.emit('popup', () => m(
                                '',
                                m('h3', 'Failed to set image ready'),
                                m('p', JSON.stringify(err)),
                                m('a.button', { onclick: e => sendToServer(dataUrl) }, 'Try again'),
                                m('a.button', { onclick: e => { emitter.emit('popup', null); Icon.refresh(); } }, 'Cancel'),
                            ));
                            m.redraw();
                        })
                        ;
                });
            m.redraw();
        })
        .catch(err => {
            console.log(err);
            emitter.emit('popup', () => m(
                '',
                m('h3', 'Failed to request upload image'),
                m('p', JSON.stringify(err)),
                m('a.button', { onclick: e => sendToServer(dataUrl) }, 'Try again'),
                m('a.button', { onclick: e => { emitter.emit('popup', null); Icon.refresh(); } }, 'Cancel'),
            ));
            m.redraw();
        })
}

const app = {
    setupModelViewer: async (vnode, canvas) => {
        const { modelPath, wid } = vnode.state;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight * 0.99;
        const viewer = await arViewer({ canvas, screenWidth, screenHeight, modelPath });
        vnode.state.viewer = viewer;
        if (wid) {
            emitter.emit('textureDataUrl', TEXTURE_PATH.replace('{id}', vnode.state.wid));
            emitter.emit('mode', MODE_SHOW_MODEL);
        } else {
            emitter.emit('mode', MODE_TAKE_PHOTO);
        }
    },

    oninit: async vnode => {
        const model = m.route.param('model');
        vnode.state.model = model;
        vnode.state.modelPath = models[model].model;
        vnode.state.guidePath = models[model].guide;
        vnode.state.drawingPath = models[model].drawing;
        vnode.state.maskPath = models[model].mask;
        vnode.state.titlePath = models[model].title;

        vnode.state.wid = m.route.param('id');
        setupListeners(vnode);
    },
    view: vnode => {
        const { mode, video, screenWidth, screenHeight,
            guidePath, maskPath, drawingPath, titlePath, wid, setupModelViewer, viewer } = vnode.state;

        return m(
            '.grid-container.full',
            {
                className: mode,
                oncreate: vnode2 => {
                    // vnode2.dom.style.height = window.innerHeight+'px';
                    // We listen to the resize event
                    const resize = () => {
                        let vh = window.innerHeight * 0.01;
                        vnode2.dom.style.setProperty('--vh', `${vh}px`);
                        vnode.state.screenWidth = window.innerWidth;
                        vnode.state.screenHeight = window.innerHeight * 0.99;
                        // console.log(`${vnode.state.screenWidth} ${vnode.state.screenHeight}`);
                    }
                    resize();
                    window.addEventListener('resize', resize);
                }
            },
            m('canvas.modelviewer', { className: mode, oncreate: vnode2 => setupModelViewer(vnode, vnode2.dom) }),
            (mode == MODE_TAKE_PHOTO) ?
                m(
                    takePhotoPage,
                    { emitter },
                    m(
                        'canvas.guide-canvas',
                        {
                            width: screenWidth,
                            height: screenHeight,
                            oncreate: vnode2 => {
                                const canvas = vnode2.dom;
                                const ctx = canvas.getContext('2d');
                                const guide = new Image();
                                guide.src = guidePath;
                                guide.onload = () => {
                                    const side = Math.min(screenWidth, screenHeight);
                                    ctx.fillStyle = 'rgba(0,0,0,0.5)';
                                    ctx.fillRect(0, 0, screenWidth, screenHeight);
                                    ctx.globalCompositeOperation = "destination-out";
                                    ctx.drawImage(guide, (screenWidth - side) / 2, (screenHeight - side) / 2, side, side);
                                };
                            }
                        }
                    )
                )
                : (mode == MODE_SHOW_DRAWING) ?
                    m(drawingPage, { emitter, viewer, guidePath: maskPath, drawingPath, titlePath })
                    : (mode == MODE_SHOW_MODEL) ?
                        m(
                            showModelPage,
                            { emitter, isViewOnly: !!wid, titlePath, screenWidth, screenHeight }
                        )
                        : (mode == MODE_SHOW_AR) ?
                            m(
                                showARPage,
                                { emitter }
                            )
                            : null,
            m('small', { style: 'bottom: 0.2rem;position: absolute;left: 0.5rem;z-index: 9999;' }, version),
            m(popup, { emitter })
        );
    }
}

const popup = {
    oninit: vnode => {
        vnode.attrs.emitter.on('popup', func => vnode.state.func = func);
    },
    view: vnode => {
        const { func } = vnode.state;
        if (!func) {
            return null;
        }
        return m(
            '.reveal-overlay',
            { style: 'display:block' },
            m(
                '.reveal',
                { style: 'display:block' },
                m('button.close-button', { onclick: e => vnode.state.func = null }, m('span[aria-hidden=true]', '×')),
                func()
            )
        )
    }
}

m.route(document.body, '/1', {
    '/:model': app,
    '/:model/:id': app,
})