import m from 'mithril';
import { Icon } from './icons';

const showARPage = {
    view: vnode => {
        const { emitter } = vnode.attrs;
        return m(
            '.grid-y.grid-frame.show-AR-page',
            vnode.children,
            m('a.close-button', {
                onclick: () => emitter.emit('mode', 'showModel')
            }, m(Icon, { id: 'ar_close' })),
            m(
                '.capture-button',
                m('a.icon-button', {
                    onclick: () => emitter.emit('saveARImage')
                }, m(Icon, { id: '4_capture_btn' }))
            )
        )
    }
}

export { showARPage as default }