import m from 'mithril';

let ids;

function requireAll(r) {
    ids = r.keys().map(k => k.replace(/\.\/(.*)\.svg/, '$1'));
    r.keys().forEach(r);
}

requireAll(require.context('../svg', true, /\.svg$/));

const Icons = {};
ids.forEach(id => {
    Icons[id] = option => {
        const { tag, ..._option } = option || {};
        return m(`${tag || ''}.svg`, _option || {}, m.trust(`<svg><use xlink:href="./img/icons.svg#${id}"></use></svg>`))
    };
})

let isRefreshing = false;

const Icon = {
    refresh: () => {
        isRefreshing = true;
        m.redraw();
        setTimeout(() => { isRefreshing = false; m.redraw(); }, 10);
    },
    view: vnode => isRefreshing ? null : m.trust(`<svg ${vnode.attrs.attrs || ''}><use xlink:href="./img/icons.svg#${vnode.attrs.id}"></use></svg>`)
}

export { Icons as default, Icon }