import ARToolkit from 'artoolkit5-js';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';

import cameraPara from '../data/camera_para.dat';
import markerPatt from '../data/pattern-c_AR_marker.patt';

const setupStream = async () => {
    const mediaStreamConstraints = {
        video: {
            height: {
                min: 480,
                ideal: 720,
                max: 1080,
            },
            width: {
                min: 649,
                ideal: 1280,
                max: 1920
            },
            // aspectRatio: 16/9,
            facingMode: "environment"
        },
        audio: false,
    };
    let stream = null;
    let videoWidth, videoHeight;
    try {
        stream = await navigator.mediaDevices.getUserMedia(mediaStreamConstraints);
        const tracks = stream.getVideoTracks();
        if (tracks.length) {
            videoWidth = tracks[0].getSettings().width;
            videoHeight = tracks[0].getSettings().height;
        }
    } catch (err) {
        console.log(err);
    }
    return {
        stream,
        videoWidth,
        videoHeight
    }
}

const createScene = async ({ engine, modelPath }) => {
    const scene = new BABYLON.Scene(engine);
    scene.clearColor = BABYLON.Color3.White();
    scene.useRightHandedSystem = true;
    await BABYLON.SceneLoader.AppendAsync('', modelPath, scene);
    const modelRoot = scene.getNodeById('__root__');
    const markerRoot = new BABYLON.AbstractMesh('markerRoot', scene);
    const material = scene.materials[0];
    scene.animationGroups.forEach(ani => ani.play(true));
    modelRoot.parent = markerRoot;
    markerRoot.rotation.x = 0.01; // Why without the change rotation, the AR won't work

    const camera = new BABYLON.FreeCamera('camera', new BABYLON.Vector3(0, -0.5, 10), scene, true);
    camera.target = new BABYLON.Vector3(0, -0.5, 0);
    // camera.rotation.z = Math.PI / 2;
    const arCamera = new BABYLON.Camera('arCamera', new BABYLON.Vector3(0, 0, 0), scene, true);
    const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(1, 1, 0), scene);
    light.intensity = 5;

    return {
        scene,
        markerRoot,
        modelRoot,
        material,
        camera,
        arCamera
    }
}

const addVideoLayer = async ({ canvas, scene, stream }) => {
    const videoLayer = new BABYLON.Layer('videoLayer', null, scene, true);
    const videoTexture = await BABYLON
        .VideoTexture
        .CreateFromStreamAsync(
            scene,
            stream,
            {},
            false
        );

    videoLayer.texture = videoTexture;

    const scaleX = 1;

    const resizeObserver = new ResizeObserver(() => {
        const canvasAspectRatio = canvas.width / canvas.height;
        let videoWidth = videoTexture.video.videoWidth;
        let videoHeight = videoTexture.video.videoHeight;
        const videoAspectRatio = videoWidth / videoHeight;
        if (canvasAspectRatio > videoAspectRatio) {
            const videoLayerScaleX = videoAspectRatio / canvasAspectRatio;
            videoLayer.scale.x = 1;
            videoLayer.scale.y = 1;

            videoTexture.uScale = 1;
            videoTexture.vScale = videoLayerScaleX;
            videoTexture.uOffset = (1 - videoTexture.uScale) * 0.5;
            videoTexture.vOffset = 0;
        } else {
             const videoLayerScaleY = canvasAspectRatio / videoAspectRatio;
            videoLayer.scale.x = 1;
            videoLayer.scale.y = 1;

            videoTexture.uScale = videoLayerScaleY;
            videoTexture.vScale = 1;
            videoTexture.uOffset = 0;
            videoTexture.vOffset = (1 - videoTexture.vScale) * 0.5;
        }
    });

    resizeObserver.observe(canvas);

    scene.onDisposeObservable.add(() => {
        resizeObserver.unobserve(canvas);
    });

    return {
        video: videoTexture.video,
        videoLayer,
        scaleX
    }
}

const setupAR = async (state) => {
    if (!state.arController) {
        const arController = await ARToolkit.ARController.initWithImage(state.video, cameraPara);
        state.arMarkerId = await arController.artoolkit.addMarker(arController.id, markerPatt);
        state.arCamera.freezeProjectionMatrix(BABYLON.Matrix.FromArray(arController.getCameraMatrix()));
        state.arController = arController;
    }
    state.isARMode = true;
    state.modelRoot.setEnabled(false);
}

const processAR = ({ arController, arMarkerId, markerRoot, modelRoot, video }) => {
    const result = arController.detectMarker(video);
    if (result !== 0) {
        console.log('Error detecting markers');
        return;
    }
    const markerNum = arController.getMarkerNum();
    for (let i = 0; i < markerNum; i++) {
        const markerInfo = arController.getMarker(i);
        // if (markerInfo.idPatt == arMarkerId) {
        if (markerInfo.idPatt == arMarkerId) {
            modelRoot.setEnabled(true);
            const visible = arController.trackPatternMarkerId(markerInfo.idPatt);
            if (visible.inPrevious) {
                arController.getTransMatSquareCont(i, visible.markerWidth, visible.matrix, visible.matrix);
            } else {
                arController.getTransMatSquare(i, visible.markerWidth, visible.matrix);
            }
            visible.inCurrent = true;
            arController.transMatToGLMat(visible.matrix, arController.transform_mat);
            arController.transformGL_RH = arController.arglCameraViewRHf(arController.transform_mat, markerRoot._worldMatrix.m);
            markerRoot._childUpdateId++;
            break;
        }
    }
}

function customLoadingScreen() {

}
customLoadingScreen.prototype.displayLoadingUI = function () {

};
customLoadingScreen.prototype.hideLoadingUI = function () {

};

const arViewer = async ({ canvas, screenWidth, screenHeight, modelPath }) => {
    const {
        stream,
        videoWidth,
        videoHeight
    } = await setupStream();
    canvas.width = screenWidth;
    canvas.height = screenHeight;
    const engine = new BABYLON.Engine(canvas, true, { preserveDrawingBuffer: true, stencil: true });
    const loadingScreen = new customLoadingScreen();
    engine.loadingScreen = loadingScreen;
    const {
        scene,
        markerRoot,
        modelRoot,
        material,
        camera,
        arCamera
    } = await createScene({ engine, modelPath });
    const {
        video,
        videoLayer,
        scaleX
    } = await addVideoLayer({canvas, scene, stream })

    canvas.width = screenWidth;
    canvas.height = screenHeight;

    const rtn = {
        isARMode: false,
        isRunning: true,
        setupAR: () => setupAR(rtn),
        setRunning: (isRunning) => {
            rtn.isRunning = isRunning;
            if (isRunning) {
                video.play();
            } else {
                video.pause();
            }
        },
        setARMode: (isARMode) => {
            if (isARMode) {
                modelRoot.rotation.set(-Math.PI / 2, Math.PI, 0);
                modelRoot.scaling.y = screenWidth / screenHeight;
                setupAR(rtn);
                scene.activeCamera = arCamera;
            } else {
                modelRoot.rotation.set(0, 0, 0);
                modelRoot.scaling.y = 1;
                markerRoot.rotation.set(0.01, 0, 0);
                markerRoot.position.set(0, 0, 0);
                rtn.isARMode = isARMode;
                scene.activeCamera = camera;
            }
        },
        showModel: (isShown) => {
            modelRoot.setEnabled(isShown);
        },
        showVideo: (isShown) => {
            videoLayer.isEnabled = isShown;
        },
        captureScreen: (onSuccess) => {
            BABYLON.Tools.CreateScreenshot(engine, arCamera, { precision: 2 }, onSuccess);
        },
        captureVideoTexture: async () => {
            const p = new Promise((resolve, reject) => {
                BABYLON.Tools.CreateScreenshot(engine, camera, { width: 1024 }, resolve);
            });
            return p;
        },
        engine,
        arCamera,
        markerRoot,
        modelRoot,
        scaleX,
        material,
        video
    };

    engine.runRenderLoop(function () {
        if (rtn.isARMode) {
            processAR(rtn);
        }
        if (rtn.isRunning) {
            scene.render();
        }
    });

    window.addEventListener('resize', function () {
        engine.resize();
    });
    engine.resize();

    return rtn;
}

export { arViewer as default }